import React, {useState} from 'react';
import {Badge, Button, Modal} from 'react-bootstrap';

const FloatingBadge = () => {
    const [showModal, setShowModal] = useState(true)

    return (
        <>
            <Button onClick={()=> setShowModal(true)} className="floating-button">
                ⚡ Oferta Exclusiva Ativada ⚡
            </Button>
            <Modal className={'text-center'} show={showModal} size={'lg'}>
                <Modal.Title className={'mt-5'}>
                    <h1>🎉Oferta exclusiva ativada🎉</h1>
                </Modal.Title>
                <Modal.Body className={'fs-5'}>
                    Vejo que se interessou por nosso convite! Estamos muito felizes em ter você
                    aqui. 🎉<br/><br/>

                    Como um dos nossos primeiros visitantes, <strong>você está elegível para uma oportunidade única: uma
                    vaga no plano de Fundadores!</strong><br/><br/>

                    🔒 Benefícios do plano fundadores:<br/><br/>

                    <strong>
                        • Contrato exclusivo com condições únicas.<br/>
                        • Preço especial garantido enquanto durar o contrato.<br/>
                        • Contato direto com nossa equipe de produto<br/><br/>
                    </strong>


                    Mas atenção! ⏳ <strong>Essa oferta é válida por tempo limitado.</strong> Para garantir sua
                    vaga, preencha o
                    formulário no final da página!<br/><br/>

                    Não perca a chance de fazer parte desse marco especial do Ki-wi.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Continuar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FloatingBadge;
