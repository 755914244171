import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FloatingLabel,
    Form,
    Image,
    Nav,
    Navbar, NavDropdown,
    NavLink,
    Row
} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import {useState} from "react";
import useQueueApiClient from "../components/useQueueApiClient";

const Home = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isWhatsapp, setIsWhatsapp] = useState(false)
    const [address, setAddress] = useState('')
    const [observation, setObservation] = useState('')
    const {queueApiClient} = useQueueApiClient()


    const sendContacts = () => {
        if (!name || name === '') {
            toast.error("Seu nome é um campo obrigatório")
            return;
        }
        if (!phone || phone === '') {
            toast.error("Seu telefone é um campo obrigatório")
            return;
        }
        if (!email || email === '') {
            toast.error("Seu e-mail é um campo obrigatório")
            return;
        }
        if (!address || address === '') {
            toast.error("O endereço do estabelecimento é um campo obrigatório")
            return;
        }

        queueApiClient.post('/contact', {
            name,
            number: phone,
            address,
            isWhatsapp,
            email,
            observation
        }).then(response => {
            toast.success("Recebemos suas informações. Logo entraremos em contato!", {
                theme: 'colored'
            })
        }).catch(err => {
            toast.warn("Tivemos um problema para receber seus dados. Envie um e-mail para contato@ki-wi.app, e retornaremos o mais rápido possível.", {
                delay: 15,
                theme: 'colored'
            })
        })
    }

    document.title = "Ki-wi"
    const glassCardBg = {
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        background: 'rgba(255, 255, 255, 0.16)',
        //boxShadow: '0 0px 2px rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(20px)',
        '-webkit-backdrop-filter': 'blur(20px)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.5)'
    }

    return (
        <>
            <Navbar fixed={"top"} className={'justify-content-between'} style={glassCardBg}>
                <Container>
                    <Navbar.Brand href={"/"}>
                        <img
                            alt=""
                            src="/kiwi-fruit.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        Ki-wi
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    <Nav.Link href="#contato">Contato</Nav.Link>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <a href="/login/merchant">Entrar</a>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <section id={"sobre"} className={'fixed-section'}>
                <Container>
                    <Row>
                        <Col className={'text-center justify-content-center mt-5 mb-5'} sm={12} md={12} lg={12}>
                            <h1 className={'fw-bolder ki-wi-title grad-text'}>
                                KI-WI
                            </h1>

                            <Row>
                                <h2 className={'display-5 mb-5'}>A FERRAMENTA DEFINITIVA PARA GESTÃO DE FILAS</h2>
                                <Col className={'mx-auto'} lg={8}>
                                    <h4>
                                        Descubra como otimizar filas e transformar seu atendimento.
                                        Eleve a qualidade da experiência, atraia mais clientes e organize seu negócio
                                        com eficiência.
                                    </h4>
                                </Col>
                            </Row>

                            <h3 className={'display-4 fw-bolder mt-5 mb-5'}>POUCOS PASSOS.
                                <br/>
                                SEM BUROCRACIA.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={"funcionalidades"} style={{
                background: 'rgb(255,255,255)'
            }}>
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Card className={'grad-feature-card-alt card-popout'}>
                                <CardBody className={'text-start justify-content-start'}>
                                    <Row>
                                        <Col lg={6}>
                                            <h1 className={'fw-bolder'}>
                                                Praticidade
                                            </h1>
                                            <h4 className={'text-muted '}>
                                                O cliente chega ao estabelecimento e, ao escanear um QR Code
                                                disponibilizado no local,
                                                é direcionado à plataforma. Após realizar um breve cadastro, ele
                                                solicita a entrada na fila de forma simples e rápida,
                                                garantindo seu atendimento com mais praticidade e sem a necessidade de
                                                esperar fisicamente.
                                            </h4>
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'text-center'}>
                                                <Image fluid={true} rounded height={'60%'} width={'60%'}
                                                       src={'https://images.emojiterra.com/microsoft/fluent-emoji/512px/1f95d_color.png'}></Image>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className={'mt-5 grad-feature-card-alt card-popout'}>
                                <CardBody className={'text-start justify-content-start'}>
                                    <Row>
                                        <Col lg={6}>
                                            <h1 className={'fw-bolder'}>
                                                Inteligência para o negócio
                                            </h1>
                                            <h4 className={'text-muted '}>
                                                Transforme dados em decisões estratégicas com os insights
                                                inteligentes
                                                do Ki-Wi.
                                                Nossa plataforma coleta e analisa informações cruciais sobre o
                                                comportamento de espera dos clientes, ajudando você a identificar
                                                tendências,
                                                ajustar operações e maximizar a eficiência.
                                            </h4>
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'text-center'}>
                                                <Image fluid={true} rounded height={'60%'} width={'60%'}
                                                       src={'https://icons.iconarchive.com/icons/microsoft/fluentui-emoji-3d/512/Sparkles-3d-icon.png'}></Image>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                            <Card className={'mt-5 grad-feature-card-alt card-popout'}>
                                <CardBody className={'text-start justify-content-start'}>
                                    <Row>
                                        <Col lg={6}>
                                            <h1 className={'fw-bolder'}>
                                                Satisfação do cliente
                                            </h1>
                                            <h4 className={'text-muted '}>
                                                A experiência do cliente é prioridade com o Ki-Wi.
                                                Nosso gerenciador de fila foi projetado para minimizar frustrações e
                                                maximizar a satisfação.
                                                Ao reduzir os tempos de espera e oferecer uma experiência mais
                                                fluida, você garante que seus clientes saiam com um sorriso no rosto.
                                            </h4>
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'text-center'}>
                                                <Image fluid={true} rounded height={'60%'} width={'60%'}
                                                       src={'https://www.iconarchive.com/download/i138152/microsoft/fluentui-emoji-3d/Sparkling-Heart-3d.1024.png'}></Image>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className={'mt-5 grad-feature-card-alt card-popout mb-5'}>
                                <CardBody className={'text-start justify-content-start'}>
                                    <Row>
                                        <Col lg={6}>
                                            <h1 className={'fw-bolder'}>
                                                Inovação constante
                                            </h1>
                                            <h4 className={'text-muted '}>
                                                Promova a inovação contínua em seu negócio com o Ki-Wi.
                                                Estamos desenvolvendo diversas funcionalidades adicionais para que você
                                                se destaque no mercado,
                                                supere a concorrência e atraia mais clientes.
                                            </h4>
                                        </Col>
                                        <Col lg={6}>
                                            <div className={'text-center'}>
                                                <Image fluid={true} rounded height={'60%'} width={'60%'}
                                                       src={'https://static.vecteezy.com/system/resources/previews/014/313/134/original/3d-yellow-lightning-delivery-speed-concept-flash-sale-promotion-png.png'}></Image>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{minHeight: '120vh', background: "#242424"}} className={'text-white'} id={"pesquisa"}>
                <Container>
                    <Row>
                        <Col className={'text-center justify-content-center mt-5 mb-5'} sm={12} md={12} lg={12}>

                            <h1 className={'display-1 fw-bolder mt-5'}>IMPULSIONE SEUS RESULTADOS</h1>
                            <br/>
                            <h3 className={'fw-bolder'}><span className={'grad-text'}>DESCUBRA COM DADOS</span> AS
                                PREFERENCIAS DOS CLIENTES.</h3>
                            <Row>
                                <Col lg={4} sm={12} md={6} className={'mx-auto my-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>A fila importa</h3>
                                            <h5>
                                                <strong>Para <span style={{
                                                    fontSize: '120%'
                                                }} className={'fw-bolder'}>73.4%</span> dos entrevistados, o tamanho da
                                                    fila influencia diretamente
                                                    a escolha do restaurante</strong>, com 30,5% do total das
                                                respostas sendo <strong>influencia MUITO.</strong>
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={4} sm={12} md={6} className={'mx-auto my-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>Evite desconforto nas filas</h3>
                                            <h5>
                                                De acordo com a pesquisa, 42,5% dos entrevistados
                                                sentem <strong>MUITO</strong> incômodo
                                                ao aguardar na fila em frente ao restaurante, enquanto 38% relatam
                                                algum incômodo, <strong>totalizando <span style={{
                                                fontSize: '120%'
                                            }} className={'fw-bolder'}>80,5%</span> dos resultados indicando
                                                incômodo.</strong>
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={4} sm={12} md={6} className={'mx-auto my-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>Flexibilidade na espera</h3>
                                            <h5 className={''}>
                                                <strong>Uma esmagadora maioria de <span style={{
                                                    fontSize: '120%'
                                                }} className={'fw-bolder'}>84.5%</span></strong> dos entrevistados
                                                responderam que <strong>GOSTARIAM
                                                de visitar
                                                outros locais</strong> enquanto aguardam sua vez.
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={4} sm={12} md={6} className={'mx-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>A experiência de espera</h3>
                                            <h5 className={''}>
                                                <strong>Para <span style={{
                                                    fontSize: '120%'
                                                }} className={'fw-bolder'}>81.3%</span> dos entrevistados gostariam de
                                                    usar um aplicativo que permita passear enquanto aguardam</strong>,
                                                com 57,5% do total das respostas alegando que <strong>gostariam
                                                MUITO.</strong>
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={4} sm={12} md={6} className={'mx-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>Frustração que afasta</h3>
                                            <h5 className={''}>
                                                <strong>O incômodo gerado em <span
                                                    style={{fontSize: '120%'}}>80.5%</span> dos entrevistados, pode
                                                    gerar um grande impacto na experiência do cliente.</strong> De
                                                acordo com um <a target="_blank" rel={'noreferrer'}
                                                                 href={'https://newsroom.accenturebr.com/br/news/2019/clientes-frustrados-estao-3-vezes-mais-propensos-abandonar-relacao-com-marca-aponta-estudo-da-accenture'}>estudo
                                                da Accenture</a>,
                                                clientes frustrados estão 3 vezes mais propensos a abandonar uma marca.
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={4} sm={12} md={6} className={'mx-auto mt-5'}>
                                    <Card className={'research-card'}>
                                        <CardBody>
                                            <h3 className={'fw-bolder mb-3'}>Fidelização pela experiência</h3>
                                            <h5>
                                                A pesquisa revela que <strong>65,8% dos entrevistados costumam
                                                frequentar
                                                restaurantes mais de uma vez por mês</strong>, e 31,2% pelo menos uma
                                                vez no mês.
                                                A grande maioria são cliente recorrentes.
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <h2 className={'display-2 fw-bolder mt-5 text-center'}>O QUE TUDO ISSO SIGNIFICA?</h2>
                        <h3 className={'text-center mb-5'}>Para a esmagadora maioria dos entrevistados,
                            a experiência de aguardar sua vez na frente do restaurante é frustrante e incômoda. Pensando
                            nisso,<span className={'grad-text'}> desenvolvemos uma solução</span> que, além de melhorar
                            a experiência
                            <span className={'grad-text'}> para mais de <span
                                style={{fontSize: '110%'}}>84%</span></span> dos entrevistados,
                            também facilitará a gestão de filas do seu restaurante.</h3>
                    </Row>
                </Container>
            </section>
            <section id={"contato"} style={{
                minHeight: '80vh',
                background: 'rgb(255,255,255)'
            }}>
                <Container>
                    <Row>
                        <Col className={'text-center justify-content-center mt-5 mb-5'} sm={12} md={12} lg={12}>

                            <h1 className={'display-1 fw-bolder mt-5'}>VENHA INOVAR</h1>
                            <br/>
                            <h4 className={'fw-bolder'}>Estamos prontos para
                                ajudar você a <span className={'grad-text'}>aprimorar e elevar</span> a experiência dos
                                seus
                                clientes e colaboradores.</h4>
                            <p style={{fontSize: "120%"}} className={'text-muted'}></p>

                            <Row>
                                <Col className={'mx-auto'} sm={12} md={12} lg={10}>
                                    <Card style={{
                                        background: 'rgb(243,243,243)'
                                    }}>
                                        <CardBody>
                                            <>
                                                <Row>
                                                    <Col lg={6} sm={12} md={6}>
                                                        <FloatingLabel controlId="floatingInput" label="Seu nome"
                                                                       className="mb-3">
                                                            <Form.Control type="text"
                                                                          onChange={event => setName(event.target.value)}
                                                                          placeholder={'nome'}/>
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col lg={4} sm={12} md={4}>
                                                        <FloatingLabel controlId="floatingInput"
                                                                       label="Número de telefone" className="mb-3">
                                                            <Form.Control type="text"
                                                                          onChange={event => setPhone(event.target.value)}
                                                                          placeholder={'telefone'}/>
                                                        </FloatingLabel>
                                                    </Col>

                                                    <Col lg={1} sm={12} md={1}>
                                                        <Form.Check className={'my-auto mx-auto'}
                                                                    type={'checkbox'}
                                                                    onChange={event => setIsWhatsapp(event.target.checked)}
                                                                    label={'Whatsapp?'}
                                                        />
                                                    </Col>
                                                </Row>

                                                <FloatingLabel controlId="floatingInput" label="E-mail"
                                                               className="mb-3">
                                                    <Form.Control type="email"
                                                                  onChange={event => setEmail(event.target.value)}
                                                                  placeholder={'mail'}/>
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="Endereço"
                                                               className="mb-3">
                                                    <Form.Control type="text"
                                                                  onChange={event => setAddress(event.target.value)}
                                                                  placeholder={'Endereço'}/>
                                                </FloatingLabel>

                                                <FloatingLabel controlId="floatingInput" label="Observação"
                                                               className="mb-3">
                                                    <Form.Control type="text"
                                                                  onChange={event => setObservation(event.target.value)}
                                                                  placeholder={'Observação'}/>
                                                </FloatingLabel>

                                                <Button variant={'primary'} onClick={sendContacts} size={'lg'}>
                                                    Solicitar contato
                                                </Button>

                                            </>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
            <footer style={{background: "#242424",
                marginTop: 2,
                minHeight: '10vh'}} id={'footer'}>
                <Container>
                    <Row>
                        <Col className={'my-auto mx-auto text-white text-center'} sm={12} md={6} lg={6}>
                            <Image className={'mt-3'} src={'/kiwi-fruit.svg'}/>
                            <p>
                                <a color={'light'} href={'/legal'}>Politica de privacidade & Termos de uso</a>
                                <br/>
                                Entre em contato: <a href={'mailTo:contato@ki-wi.app'}>contato@ki-wi.app</a>
                                <br/>
                                &copy; {new Date(Date.now()).getFullYear()} Ki-wi
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <ToastContainer/>
        </>
    )
}

export default Home;