export const formatDateTime = (date) => {
    let horas = date.getHours();
    let minutos = date.getMinutes();

    horas = horas < 10 ? '0' + horas : horas;
    minutos = minutos < 10 ? '0' + minutos : minutos;

    return horas + ':' + minutos;
}

export const formatDate = (date) => {
    let day = date.getDay();
    let month = date.getMonth()
    let year = date.getFullYear()

    return `${day}/${month}/${year}`
}

export const formatStringDate = (str) => {
    let day = str.split('-')[2]
    let month = str.split('-')[1]
    let year = str.split('-')[0]

    return `${day}/${month}/${year}`
}

export function datePlusDays(days) {
    const currentDate = new Date();
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + days);
    return newDate;
}